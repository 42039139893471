<script>
import { HEIGHTS } from '@/util/constants'
import { reactive, toRefs, onMounted } from 'vue'
import { useStore } from 'vuex'

const questionText = 'What is your height?'

const stepName = 'Height'

export default {
  name: stepName,

  emits: ['next-step'],

  setup(_, {emit}) {
    const store = useStore()

    onMounted(() => {
      store.commit('SET_IS_LOADING', false)
    })

    const state = reactive({
      height: 64 //default 5'4"
    })

    const setAnswer = () => {
      store.commit('SET_FIELD', {field: 'height', value: `${state.height}_inches`})
      emit('next-step', stepName)
    }

    return {
      setAnswer,
      questionText,
      HEIGHTS,
      ...toRefs(state)
    }
  }
}
</script>

<template>
  <div>
    <div class="row">
      <div class="box">
        <h2 class="main-heading button-answers-heading">{{ questionText }}</h2>
      </div>
    </div>
    <div class="row">
      <div class="box">
        <select
          class="select-dropdown height-select-dropdown"
          v-model="height"
        >
          <option
            v-for="height in HEIGHTS"
            :value="height.inches"
          >
            {{height.feet}}
          </option>
        </select>
      </div>
    </div>

    <div class="row">
      <div class="box">
        <button
          class="main-button main-button-w"
          @click="setAnswer"
        >
          Continue
        </button>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
@import '../../../assets/styles/component_styles/questionnaire/height.scss';
</style>